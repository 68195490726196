import React                     from 'react';
import styled                    from '@emotion/styled';
import { Container, Col, Row }   from 'react-grid-system';
import Img                       from 'gatsby-image';
import Breakpoint                from 'react-socks';
import { Flex, Box }             from '@chakra-ui/react';
import { GiDiamondRing, GiShop } from 'react-icons/gi';
import { BsTools }               from 'react-icons/bs';


import CallToAction              from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
import Spacer
                                 from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper
                                 from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import RandomBrandHeaderImages
                                 from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';
import MoreExpander
                                 from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import Heading                   from '@interness/web-core/src/components/text/Heading/Heading';
import LiveAnnouncements
                                 from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import SEO                       from '@interness/web-core/src/components/modules/SEO/SEO';
import Mounted                   from '@interness/web-core/src/components/structure/Mounted/Mounted';
import RandomSquareGalleryImages from '@interness/brands-addon/src/components/media/RandomSquareGalleryImages';
import FramedImage               from '@interness/theme-sonora/src/components/FramedImage';
import { graphql }               from 'gatsby';
import BrandsDisplay             from '@interness/theme-sonora/src/components/Display/BrandsDisplay';


const List = styled.ul`
    margin-left: 0;

    li {
        margin: 0;
    }
`;


function IndexPage({ data }) {
  return (
    <>
      <SEO title="Startseite"/>
      <Mounted>
        <Breakpoint small down>
          <RandomSquareGalleryImages/>
        </Breakpoint>
        <Breakpoint medium up>
          <RandomBrandHeaderImages/>
        </Breakpoint>
      </Mounted>
      <Wrapper>
        <Spacer/>
        <LiveAnnouncements/>
        <Spacer/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'} icon={<GiDiamondRing/>}>Juwelier Holtermann</Heading>
          <Flex flexWrap="wrap">
            <Box w={['100%', '100%', '50%', '50%']} p={[4, 4, 4, 12]} paddingRight={0}>
              <FramedImage>
                <Img fluid={data.laden.images[0].file.localFile.childImageSharp.fluid}/>
              </FramedImage>
            </Box>
            <Box w={['100%', '100%', '50%', '50%']} p={[4, 4, 4, 12]}>
              <p>Willkommen bei Juwelier Holtermann, Ihrem exklusiven Juwelier im historischen Herzen von Krefeld
                Uerdingen. In unserer Meisterwerkstatt, geführt von erfahrenen Uhrmachermeistern, bieten wir Ihnen eine
                exquisite Auswahl an Uhren, Schmuck, Trauringen, Eheringen und Antragsringen, die mit handwerklicher
                Präzision und Liebe zum Detail gefertigt werden.</p>
              <p>Unser Juweliergeschäft in Krefeld Uerdingen zeichnet sich durch eine jahrzehntelange Tradition und
                Leidenschaft für feine Uhren und edlen Schmuck aus. Ob Sie auf der Suche nach dem perfekten Antragsring
                sind, einzigartige Eheringe wünschen oder ein individuelles Schmuckstück kreieren möchten – bei Juwelier
                Holtermann finden Sie einzigartige Schätze, die Ihre persönlichen Momente unvergesslich machen.</p>
              <p>Wir sind stolz darauf, Ihnen nicht nur Produkte höchster Qualität anzubieten, sondern auch einen
                umfassenden Service. Von professionellen Reparaturen in unserer Meisterwerkstatt bis hin zum
                Altgoldankauf, bei dem Sie Ihre alten Schätze in neue Kreationen verwandeln können, steht unser
                erfahrenes Team Ihnen mit Rat und Tat zur Seite.</p>
              <p>Entdecken Sie bei uns die Welt der feinen Uhren und des exquisiten Schmucks, in der Tradition und
                Innovation aufeinandertreffen. Lassen Sie sich von der Vielfalt unserer Kollektionen inspirieren und von
                der Expertise unserer Uhrmachermeister und Schmuckexperten beraten. Wir freuen uns darauf, Sie bei
                Juwelier Holtermann in Krefeld Uerdingen begrüßen zu dürfen.</p>
              <p>Bis bald in Uerdingen, Ihre Familie Lohmar mit Team</p>
            </Box>
          </Flex>
        </section>
        <section>
          <Heading tag={'h2'} icon={<GiShop/>}>Aktuelle Kollektionen</Heading>
          <Spacer height={40}/>
          <BrandsDisplay/>
        </section>
        <Spacer/>
        <section>
          <Heading tag={'h2'} icon={<BsTools/>}>Unser Service für Sie</Heading>
          <p>Kompletter Service ist für unser Team selbstverständlich.<br/>
            Neuwertermittlung: Bewertung von Schmuckstücken und Aufarbeitung
          </p>
          <MoreExpander>
            <Container fluid>
              <Row>
                <Col md={6}>
                  <h3>Armbanduhren</h3>
                  <List>
                    <li>mechanische Uhren / Handaufzug</li>
                    <li>Quarzarmbanduhren</li>
                    <li>Automatikuhren</li>
                    <li>Batteriewechsel</li>
                    <li>Komplette Revisionen</li>
                    <li>Taschenuhren</li>
                    <li>Glasersatz</li>
                    <li>Leder-, Metall-, Kunststoffuhrarmbänder</li>
                    <li>Bandanpassung</li>
                  </List>
                </Col>
                <Col md={6}>
                  <h3>Goldschmiedereparaturen</h3>
                  <List>
                    <li>Goldschmiedereparaturen aller Art</li>
                    <li>Kette und Ösen zulöten, Verschlüsse ersetzen</li>
                    <li>Ringgrößen ändern</li>
                    <li>Perlenketten neu fassen oder knoten</li>
                    <li>Trauringumarbeitung</li>
                    <li>Neuanfertigungen</li>
                    <li>Schmuck reinigen und aufarbeiten</li>
                    <li>Gravuren in Ringe, Anhänger, Uhren, usw.</li>
                  </List>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <h3>Großuhren</h3>
                  <List>
                    <li>Heim – und Tischuhren, Jahresuhren</li>
                    <li>Standuhren</li>
                    <li>Regulatoren</li>
                  </List>
                </Col>
                <Col md={6}>
                  <h3>Altgoldankauf</h3>
                  <List>
                    <li>Wir kaufen Altgold aus vielen, unterschiedlichen Bereichen an:</li>
                    <li>Zahngold, Goldnuggets, Altgold aus Schmuckstücken.</li>
                    <li>Wir prüfen fachkundig und unkompliziert und vergüten nach aktuellen Edelmetallkursen.</li>
                    <li>Altgoldankauf ist Vertrauenssache</li>
                  </List>
                </Col>
              </Row>
            </Container>
          </MoreExpander>
        </section>
        <Spacer/>
          <CallToAction/>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;

export const query = graphql`
    query {
        laden: directusMediaCollection(name: {eq: "laden"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 800, maxHeight: 800, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
